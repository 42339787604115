<template>
  <div>
    <BHeader class="top-0 absolute hidden lg:block" />
    <BSpinner v-if="isLoading" />
    <div
      class="
        px-6
        lg:hidden
        flex flex-col
        min-h-screen
        text-sm
        xs:text-base
        lg:mt-32
      "
    >
      <div class="pt-4 pb-8 h-10">
        <button
          @click="$router.go(-1)"
          class="
            text-4xl
            h-5
            focus:outline-none
            overflow-hidden
            flex
            items-center
          "
        >
          &times;
        </button>
      </div>

      <form @submit.prevent="handleSubmit">
        <h2 class="mb-12 mt-1 font-semibold text-lg xs:text-xl">
          Sign in to your account
        </h2>
        <div class="flex flex-col my-6">
          <label class="text-text-primary font-semibold" for="email"
            >Email</label
          >
          <input
            class="
              border
              rounded
              my-2
              p-3.5
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            :class="
              errorMessage
                ? 'border border-border-danger'
                : 'border-border-primary'
            "
            placeholder="Email"
            v-model.trim="email"
            required
            type="email"
            id="email"
            @keyup="errorMessage = ''"
          />
        </div>
        <div class="flex flex-col mt-6">
          <label class="text-text-primary font-semibold" for="password"
            >Password</label
          >
          <div
            class="
              flex
              items-center
              border
              rounded
              my-2
              overflow-hidden
              input-shadow
            "
            :class="
              errorMessage
                ? 'border border-border-danger'
                : 'border-border-primary'
            "
          >
            <input
              class="
                py-3.5
                pl-3.5
                text-sm
                font-medium
                flex-grow
                focus:outline-none
              "
              @keyup="errorMessage = ''"
              :type="passwordType"
              v-model="password"
              placeholder="password"
              required
              id="password"
            />
            <div class="cursor-pointer px-3.5" @click="toggleHidePassword">
              <PasswordHiddenIcon v-if="hidePassword" />
              <PasswordShownIcon v-else />
            </div>
          </div>
        </div>
        <p class="text-text-danger font-medium text-xs">{{ errorMessage }}</p>

        <!-- <p class="text-right">
              <router-link to="#">Forgot password?</router-link>
            </p> -->

        <button
          class="
            bg-btn-bg-primary
            text-btn-text-primary
            rounded
            w-full
            mt-10
            py-4
            font-bold
          "
          type="submit"
          :disabled="!isFilled"
        >
          Sign In
        </button>

        <div class="flex justify-between mt-6">
          <router-link
            to="/forgot-password"
            class="font-semibold text-text-alternate-4"
            >Forgot password?</router-link
          >

          <router-link class="font-semibold text-text-alternate-4" to="/signup"
            >Sign Up Now</router-link
          >
        </div>
      </form>
    </div>

    <div class="hidden h-screen lg:flex mt-24 pt-2 justify-center ">
      <div class="b-card px-26">
        <div
          class="
            px-6
            lg:flex
            hidden
            flex-col
            min-h-screen
            text-sm
            xs:text-base
            lg:mt-6
          "
        >
          <div class="pt-4 pb-8 h-10"></div>

          <form @submit.prevent="handleSubmit">
            <div class="flex mb-16">
              <a
                href="javacscript:void(0)"
                @click="$router.go(-1)"
                class="
                  text-4xl
                  h-5
                  focus:outline-none
                  overflow-hidden
                  flex
                  items-center
                "
              >
                <LeftArrowIcon class="h-6 fill-current" />
              </a>

              <h2 class="font-semibold ml-6 text-lg xs:text-xl">
                Sign in to your account
              </h2>
            </div>
            <div class="flex flex-col my-6">
              <label class="text-text-primary font-semibold" for="email"
                >Email</label
              >
              <input
                class="
                  border
                  rounded
                  my-2
                  p-3.5
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                :class="
                  errorMessage
                    ? 'border border-border-danger'
                    : 'border-border-primary'
                "
                placeholder="Email"
                v-model.trim="email"
                required
                type="email"
                id="email"
                @keyup="errorMessage = ''"
              />
            </div>
            <div class="flex flex-col mt-6">
              <label class="text-text-primary font-semibold" for="password"
                >Password</label
              >
              <div
                class="
                  flex
                  items-center
                  border
                  rounded
                  my-2
                  overflow-hidden
                  input-shadow
                "
                :class="
                  errorMessage
                    ? 'border border-border-danger'
                    : 'border-border-primary'
                "
              >
                <input
                  class="
                    py-3.5
                    pl-3.5
                    text-sm
                    font-medium
                    flex-grow
                    focus:outline-none
                  "
                  @keyup="errorMessage = ''"
                  :type="passwordType"
                  v-model="password"
                  placeholder="password"
                  required
                  id="password"
                />
                <div class="cursor-pointer px-3.5" @click="toggleHidePassword">
                  <PasswordHiddenIcon v-if="hidePassword" />
                  <PasswordShownIcon v-else />
                </div>
              </div>
            </div>
            <p class="text-text-danger font-medium text-xs">
              {{ errorMessage }}
            </p>

            <!-- <p class="text-right">
              <router-link to="#">Forgot password?</router-link>
            </p> -->

            <button
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                rounded
                w-full
                mt-10
                py-4
                font-bold
              "
              type="submit"
              :disabled="!isFilled"
            >
              Sign In
            </button>

            <div class="flex justify-between mt-6">
              <router-link
                to="/forgot-password"
                class="font-semibold text-text-alternate-4"
                >Forgot password?</router-link
              >

              <router-link
                class="font-semibold text-text-alternate-4"
                to="/signup"
                >Sign Up Now</router-link
              >
            </div>
          </form>
        </div>
      </div>
    </div>
    <BModal v-if="isFailure" @close="closeModal">
      <div class="text-center flex flex-col items-center">
        <FailureIcon class="my-4 mx-auto" />
        <h2 class="text-xl text-text-primary font-semibold my-4">Sorry!</h2>
        <p class="text-xs xs:text-sm text-alternate-3">
          {{ error }}
        </p>
        <button
          class="
            bg-btn-bg-primary
            max-w-sm
            text-btn-text-primary
            rounded
            w-full
            mt-10
            py-4
            font-bold
            text-sm
            xs:text-base
            focus:outline-none
          "
          @click="closeModal"
        >
          Retry
        </button>
      </div>
    </BModal>

  </div>
  <BFooterLG class="hidden  bottom-0 mt- mb-16 lg:block" />
</template>

<script>
import { reactive, toRefs, ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import BSpinner from '@/components/ui/BSpinner';
import { useApi } from '@/cmp-functions/useApi';
import { loginUser } from '@/services/api';
import BModal from '@/components/ui/BModal';
import BHeader from '../components/ui/BHeader.vue';
import BFooterLG from '../components/ui/BFooterLG.vue';

export default {
  name: 'Login',

  components: {
    BSpinner,
    BModal,
    BHeader,
    BFooterLG,
  },

  setup() {
    const userDetails = reactive({
      password: '',
      email: '',
    });

    const hidePassword = ref(true);
    const errorMessage = ref('');
    const toggleHidePassword = () => {
      hidePassword.value = !hidePassword.value;
    };
    const passwordType = computed(() =>
      hidePassword.value ? 'password' : 'text'
    );

    const [response, authUser, { setIsFailure }] = useApi(loginUser);
    const closeModal = () => setIsFailure(false);
    const handleSubmit = () => {
      authUser(userDetails);
    };

    const router = useRouter();

    watch(response, (newResponse) => {
      const { isSuccess } = newResponse;

      if (isSuccess) {
        router.push('/explore');
      } else {
        errorMessage.value = newResponse.error;
      }
    });

    const isFilled = computed(() => {
      const { password, email } = userDetails;
      return password && /.+@.+\..+/.test(email);
    });

    return {
      ...toRefs(userDetails),
      ...toRefs(response),
      hidePassword,
      isFilled,
      handleSubmit,
      closeModal,
      toggleHidePassword,
      passwordType,
      errorMessage,
    };
  },
};
</script>
<style lang="scss" scoped>
.b-card {
  height: 475px !important;
}
</style>
